<template>
  <v-row justify="start">
    <v-dialog v-model="d_nohp" persistent max-width="450px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end pa-1">
          <v-btn small @click="$emit('close')" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="px-5 pb-5">
          <h3 class="text-center">Isi Nomor HP</h3>
          <p class="mb-4 text-center small_txt">
            Sebelum melakukan konsultasi anda harus mengisi nomor hp terlebih
            dahulu. Pastikan nomor hp Anda Aktif!
          </p>

          <div class="d-flex align-center">
            <v-text-field
              label="Nomor HP"
              v-model="no_hp"
              required
              dense
              outlined
              hide-details="auto"
            ></v-text-field>
            <v-btn @click="updateData" class="pa-4 ml-3" color="#3b053c" dark
              >Simpan</v-btn
            >
          </div>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
        <v-card-actions class="pa-0"> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "alertFillnohp",
  props: ["d_nohp", "inventory"],
  computed: {
    ...mapState({
      profile: (state) => state.cust.myProfile,
    }),
  },
  data() {
    return {
      no_hp: "",
      loading: false,
    };
  },

  methods: {
    // fungsi ini buat ngasih toleransi ke pelanggan 10 menit
    updateData() {
      this.loading = true;
      let data = {
        id: this.profile.id,
        no_hp: this.no_hp,
      };

      this.$store
        .dispatch("cust/updateUser", data)
        .then(() => {
          this.$emit("success");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Update Nomor HP Berhasil!",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>
