<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <FillNoHP
        :d_nohp="dialog"
        @close="dialog = false"
        @success="afterFillNohp"
      />
    </div>
    <v-card
      class="radius-card d-flex"
      style="box-sizing: border-box"
      min-height="500px"
      flat
    >
      <div class="toolbar pa-3">
        <div class="mb-2">
          <p class="ma-0 small_txt">Cari Nama Psikolog</p>
          <div class="d-flex">
            <v-text-field
              v-model="find"
              placeholder="Ketik disini"
              dense
              solo
              flat
              hide-details="auto"
              class="rounded mr-1"
              clearable
              @click:clear="resetFind"
            ></v-text-field>
            <v-btn
              depressed
              dark
              @click="fetchData()"
              color="#3b053c"
              min-width="10px"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </div>
        </div>
        <div class="d-flex justify-center">
          <div class="another_filter">
            <p class="ma-0 small_txt">Filter lain</p>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="my-5">
          <p class="ma-0 small_txt">Topik apa yang ingin Anda selesaikan?</p>
          <div class="d-flex">
            <v-select
              flat
              class="rounded"
              :items="topik"
              v-model="selectedTopic"
              item-text="txt"
              item-value="val"
              dense
              placeholder="Klik disini"
              hide-details="auto"
              solo
            ></v-select>
          </div>
        </div>
        <div class="mb-5">
          <p class="ma-0 small_txt">Pilih jenis kelamin</p>
          <div class="d-flex">
            <v-select
              flat
              class="rounded"
              v-model="selectedGender"
              :items="gender"
              placeholder="Klik disini"
              item-text="txt"
              item-value="val"
              hide-details="auto"
              dense
              solo
            ></v-select>
          </div>
        </div>
        <div class="mb-5">
          <p class="ma-0 small_txt">Pilih rentang umur</p>
          <div class="d-flex">
            <v-select
              flat
              class="rounded"
              placeholder="Klik disini"
              v-model="selectedAge"
              :items="age"
              item-text="txt"
              return-object
              dense
              hide-details="auto"
              solo
            ></v-select>
          </div>
        </div>
        <div class="mb-5">
          <p class="ma-0 small_txt">Pilih agama</p>
          <div class="d-flex">
            <v-select
              flat
              v-model="selectedReligion"
              class="rounded"
              placeholder="Klik disini"
              :items="religion"
              dense
              hide-details="auto"
              solo
            ></v-select>
          </div>
        </div>
        <v-btn dark color="#3b053c" depressed block @click="fetchData()">
          <v-icon class="mr-2">mdi-filter-outline</v-icon> Filter
        </v-btn>
      </div>

      <!-- ================================================================== LIST PSIKOLOG -->
      <div class="list_psy">
        <div class="header d-flex align-center justify-space-between pa-3">
          <div class="d-flex align-center">
            <div class="circle_ttl mr-2">
              <div class="litle_"></div>
            </div>
            <h3>List Psikolog</h3>
          </div>
          <v-btn
            dark
            color="#3b053c"
            to="/public/dashboard-counseling"
            depressed
          >
            <v-icon class="mr-2">mdi-clipboard-text-clock</v-icon>
            Riwayat Konseling</v-btn
          >
        </div>
        <div class="pa-3" v-if="list.length">
          <v-row>
            <v-col cols="6" v-for="(item, idx) in list" :key="idx">
              <v-card class="">
                <div class="d-flex align-center px-3 py-2">
                  <div class="pp">
                    <img
                      :src="
                        item.psycholog.photo_profile
                          ? `${env}/upload/photo_profile/${item.psycholog.id}/${item.psycholog.photo_profile}`
                          : dummy
                      "
                      class="props_img"
                      alt=""
                    />
                  </div>
                  <div class="info_">
                    <b>{{ item.psycholog.nama_lengkap }}</b>
                    <p class="ma-0 color_txt">
                      {{
                        item.psycholog.bidang_id == 1
                          ? "Psikologi Klinis"
                          : item.psycholog.bidang_id == 2
                          ? "Psikologi Industri & Organisasi"
                          : "Psikologi Pendidikan"
                      }}
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-center">
                  <v-card
                    class="py-2 mr-3 d-flex"
                    flat
                    v-if="item.psycholog.rating_waktu"
                  >
                    <v-icon color="#3b053c">mdi-clock</v-icon>
                    <v-icon color="orange">mdi-star</v-icon>
                    {{ item.psycholog.rating_waktu }}
                  </v-card>
                  <v-card
                    class="py-2 mr-3 d-flex"
                    flat
                    v-if="item.psycholog.rating_kualitas"
                  >
                    <v-icon color="#3b053c">mdi-head-cog</v-icon>
                    <v-icon color="orange">mdi-star</v-icon>
                    {{ item.psycholog.rating_kualitas }}
                  </v-card>
                  <v-card
                    class="py-2 mr-3 d-flex"
                    flat
                    v-if="item.psycholog.rating"
                  >
                    <v-icon color="#3b053c">mdi-thumb-up</v-icon>
                    <v-icon color="orange">mdi-star</v-icon>
                    {{ item.psycholog.rating }}
                  </v-card>
                </div>
                <v-divider></v-divider>
                <div class="d-flex justify-space-between pa-3">
                  <div></div>
                  <div>
                    <v-btn
                      depressed
                      dark
                      color="#3b053c"
                      @click="checkNoHp(item)"
                      >pilih</v-btn
                    >
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="d-flex justify-center mt-3">
            <div class="d-flex align-center">
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('prev')"
                ><v-icon small>mdi-arrow-left</v-icon></v-btn
              >
              <div class="mx-3 small_txt">{{ page }} of {{ total_page }}</div>
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('next')"
                ><v-icon small>mdi-arrow-right</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import FillNoHP from "./Modal/fillNoHP.vue";
export default {
  name: "VerifiedPsy",
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      dummy: (state) => state.dummy,
      profile: (state) => state.cust.myProfile,
    }),
  },
  data() {
    return {
      loading: false,
      find: "",
      // value sortir
      selectedTopic: "",
      selectedGender: "",
      selectedAge: "",
      selectedService: "",
      selectedReligion: "",
      ageFrom: "",
      ageTo: "",
      //   SELECTABLE ITEMS
      topik: [
        {
          txt: "Gangguan psikologis (klinis)",
          val: 1,
        },
        {
          txt: "Industri dan organisasi",
          val: 2,
        },
        {
          txt: "Pendidikan",
          val: 3,
        },
      ],
      gender: [
        {
          txt: "Laki-laki",
          val: "Laki-laki",
        },
        {
          txt: "Perempuan",
          val: "Perempuan ",
        },
        {
          txt: "Keduanya",
          val: "",
        },
      ],
      age: [
        {
          txt: "Usia 20 - 40 tahun",
          from: 20,
          to: 40,
        },
        {
          txt: "> 40 tahun",
          from: 40,
          to: 200,
        },
      ],
      religion: [
        "Islam",
        "Protestan",
        "Katholik",
        "Hindu",
        "Buddha",
        "Konghucu",
        "Lainya",
      ],
      page: 1,
      limit: 100,
      total_page: null,
      list: [],
      dialog: false,
      pocket: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  components: { FillNoHP },

  methods: {
    resetFind() {
      this.find = "";
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        find: this.find,
        page: this.page,
        limit: this.limit,
        bidang: this.selectedTopic,
        gender: this.selectedGender,
        religion: this.selectedReligion,
        ageFrom: this.selectedAge.from,
        ageTo: this.selectedAge.to,
      };
      this.$store.dispatch("counseling/listVerifiedPsy", data).then((data) => {
        this.list = data.data;
        this.total_page = data.last_page;
        this.loading = false;
      });
    },
    paginate(state) {
      if (state == "next") {
        if (this.page < this.total_page) {
          this.page++;
          this.fetchData();
        }
      } else {
        if (this.page > 1) {
          this.page--;
          this.fetchData();
        }
      }
    },
    checkNoHp(item) {
      if (this.profile.no_hp) {
        this.$router.push(`/counseling/prepare-book/${item.psycholog.id}`);
      } else {
        this.pocket = item.psycholog;
        this.dialog = true;
      }
    },

    afterFillNohp() {
      console.log("acumalaka");
      this.$router.push(`/counseling/prepare-book/${this.pocket.id}`);
    },
  },
};
</script>

<style scoped>
.toolbar {
  width: 30%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 70%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.pp {
  width: 100px;
}
.props_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
